import React, { useCallback, useEffect } from 'react'
import { DropEvent, useDropzone } from 'react-dropzone'
import uploadIcon from '../../assets/Icons/uploadIcon.svg'

export interface FileObj extends File {
	name: string
	size: number
	FileSize: number
	FileTitle: string
	FileType: string
	FileUrl: string
	id: string
	FileBlob?: any
}
export interface Props {
	onChange: (file: FileObj) => void
	files: FileObj[]
	max?: number
}
export const DropZone = (props: Props) => {
	const [loading, setLoading] = React.useState<boolean>(false)
	const [fileUploadError, setFileUploadError] = React.useState<string>('')
	const [files, setFiles] = React.useState<FileObj[]>([])
	const [max, setMax] = React.useState<number>(5)
	useEffect(() => {
		setFiles([...props.files])
		if (props.max) {
			setMax(props.max)
		}
	}, [props.files, props.files.length])
	const onDrop = useCallback(
		(acceptedFiles: any[], fileRejections: any, event: DropEvent) => {
			if (acceptedFiles.length + files.length > max) {
				setFileUploadError('Upload failed. File type not supported')
				return
			}
			acceptedFiles.forEach((file: any) => {
				const reader = new FileReader()
				setLoading(true)
				reader.onabort = (event) => {
					setLoading(false)
				}
				reader.onerror = () => {
					setLoading(false)
				}
				reader.onload = () => {
					setFileUploadError('')
					setLoading(false)
					// Do whatever you want with the file contents
					const binaryStr = reader.result
					// console.log(binaryStr);
					props.onChange(file)
				}
				reader.readAsArrayBuffer(file)
			})
		},
		[]
	)
	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: {
			'image/jpeg': ['.png', '.jpg', '.bmp', '.gif', '.hiec'],
			'text/csv': ['.csv'],
			'application/pdf': ['.pdf'],
			'application/vnd.ms-powerpoint': ['.ppt'],
			'application/vnd.openxmlformats-officedocument.presentationml.presentation':
				['.pptx'],
			'application/vnd.ms-excel': ['.xls'],
			'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
				'.xlsx'
			],
			'application/msword': ['.doc'],
			'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
				['.docx'],
			'audio/mpeg': ['.mp3'],
			'video/mp4': ['.mpeg', '.mp4', '.wma']
		},
		maxFiles: max - (props.files.length ?? 0) ?? max,
		maxSize: 5242880,
		noClick: props.files.length >= max,
		noDrag: props.files.length >= max,
		noDragEventsBubbling: props.files.length >= max,
		onDropRejected: (fileRejections, event) => {
			if (event) {
				event.preventDefault()
			}
			fileRejections.forEach((file) => {
				file.errors.forEach((err) => {
					if (err.code === 'file-too-large') {
						setFileUploadError(
							'Upload failed, the file size exceeded maximum allowance limit of 5MB.'
						)
					}

					if (err.code === 'file-invalid-type') {
						setFileUploadError('Upload failed. File type not supported')
					}
				})
			})
		}
	})

	return (
		<div {...getRootProps()}>
			<input {...getInputProps()} />
			<div className="mt-1 relative">
				<div className="p-4 mt-2 text-center border-2 border-dashed border-lavender">
					{fileUploadError && (
						<span className="font-poppins-400 text-red-800 text-sm">
							{fileUploadError}
						</span>
					)}
					<h2 className="font-poppins-500 text-gray-900">
						Drag and drop files here
					</h2>
					<p className="text-gray-500 text-xs mt-2">
						Upload up to 5 files, each with a maximum size of 5MB. File formats:
						mp3, mp4, mpeg, wma, doc, gif, jpg, bmp, png, txt, csv, xls, ppt,
						pdf.
					</p>

					<button
						className="text-sm font-poppins-500 text-white mt-4  rounded-md shadow-sm  btn-lavender"
						onClick={(ev) => {
							ev.preventDefault()
						}}
					>
						<img src={uploadIcon} alt="upload" className="inline-block mr-2" />
						Browse files
					</button>
				</div>
			</div>
		</div>
	)
}
