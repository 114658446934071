const baseUrl = 'http://localhost:8000'

const apiUrl = {
	baseUrl: baseUrl,
	login: '/api/auth/tcr/signin',
	claim: '/api/users/claim',
	verifyemail: '/api/auth/verifyemail',
	verifynewpassword: '/api/auth/verifynewpassword',
	createNewPassword: '/api/reset/newpwd',
	//People
	getPeople: '/api/people/all',
	searchPeople: '/api/people/search',
	sortPeople: '/api/people/sort',
	allFilterLookups: '/api/people/allfilterlookups',
	filterParams: '/api/people/filterparams',
	filterPeople: '/api/people/filter',
	inviteToRegisterSingle: '/api/people/inviteuser/single',
	inviteToRegisterMultiple: '/api/people/inviteuser/multiple',
	exportCSV2: '/api/people/exportall',
	exportCSV: '/api/people/exportfilter',
	//learner profile
	people_viewProfile: '/api/learner_profile/view_profile',
	people_activityFeed: '/api/learner_profile/activity_feed',
	people_careerPath: '/api/learner_profile/career_path',
	people_raiseflag: '/api/learner_profile/raise_flag',
	people_filter: '/api/learner_profile/filter_params',
	people_coursename: '/api/learner_profile/course_names',
	people_courseupdate: '/api/learner_profile/course_update',
	people_updatepersonal: '/api/learner_profile/personal',
	people_updatecontact: '/api/learner_profile/contact',
	people_updatedemographic: '/api/learner_profile/demographic',
	people_updateemployee: '/api/learner_profile/employee',
	//Profile
	viewPersonalProfile: '/api/tcr/users/view/personal_profile',
	profileResetPassword: '/api/users/profile_resetpassword',
	updatePersonalProfile: '/api/users/profile/personal',

	updateContact: '/api/users/profile/contact',
	updateDemographic: '/api/users/profile/demographic',
	updateEmployee: '/api/users/profile/employee',
	profilePicture: '/api/users/profile/image',
	//User
	resetPassword: '/api/reset/verificationcode/send',
	registryVerifyemail: '/api/register/verifyemail',
	config: '/api/users/config',

	//Notification
	getNotifications: '/api/notifications/view',
	markOneAsRead: '/api/notifications/markone',
	markAll: '/api/notifications/markall',
	notificationFilterParams: '/api/notifications/filters_params',
	getNotificationSettings: '/api/notifications/get_user_settings',
	setNotificationSettings: '/api/notifications/save_user_settings',

	//Dashboard
	getDashboardAllStats: '/api/dashboard/all_stats',
	timeUntilCompletion: '/api/dashboard/time_until_completion',
	trainingProvider: '/api/dashboard/training_provider',
	notificationFilter: '/api/notifications/apply_filters',
	learnerAllStats: '/api/dashboard/learner_stats',
	getDashboardFilters: '/api/dashboard/filterparams',
	getDashboardFilteredStats: '/api/dashboard/filtered_stats',
	verifyResetCode: '/api/reset/verificationcode/verify',
	//reports
	getReports: '/api/report/list',
	reportUploadFile: '/api/report/upload-file',
	paginatedCourses: '/api/tcr/reports/view/ClientWiseReports',
	searchReports: '/api/tcr/reports/search/ClientReports',
	getIndividualReports: 'api/tcr/reports/view/IndividualCourseReports',
	getLookUps: '/api/tcr/reports/lookupparams',
	updateIndividualReport:
		'/api/tcr/reports/update/LearnerIndividualCourseReports',
	filedropTCR: '/api/tcr/reports/filedrop/upload_file',
	downloadTemplate: '/api/tcr/reports/filedrop/download_template',
	getClientLearners: '/api/tcr/reports/get_client_learners',
	//TCR  Profile
	updatePersonalInfo: '/api/tcr/users/updateprofile/personal',
	updateCompanyInfo: '/api/tcr/users/updateprofile/company',
	updateProfileImage: '/api/tcr/users/updateprofile/image',
	deleteProfileImage: '/api/tcr/users/updateprofile/image',
	tcrResetPassword: '/api/tcr/users/profile_resetpassword',
	tcrRefresh: '/api/tcr/auth/refresh',
	tcrExportCSV: '/api/tcr/reports/exportcsv',
	tcrVerifyEmail: '/api/tcr/users/verify_email',

	//trainers
	getTrainers: '/api/tcr/trainers/gettrainers',
	// viewTrainerProfile: "/api/tcr/trainers/view/trainerprofile",
	viewTrainerProfile: '/api/tcr/trainers/profile_trainer',
	// deleteTrainerProfileImage: "/api/tcr/trainers/updateptrainerrofile/image",
	deleteTrainerProfileImage: '/api/tcr/trainers/updateprofile/image',
	// updateTrainerProfileImage: "/api/tcr/trainers/updatetrainerprofile/image",
	updateTrainerProfileImage: '/api/tcr/trainers/updateprofile/image',
	updatePersonalInfoTrainer: '/api/tcr/trainers/updateprofile/personal',
	inviteTrainerTCR: '/api/tcr/trainers/invite_trainer',
	verifyTrainer: '/api/register/queryemail',
	deletTrainer: '/api/tcr/trainers/delete_trainer',
	registerTrainer: '/api/register/saveuser',
	learnerProfile: '/api/tcr/trainers/learner_profile',
	trainerFilter: '/api/tcr/trainers/filter_params',
	paginationClient: '/api/tcr/trainers/get_clients',
	updateTrainerProfile: '/api/tcr/trainers/update/trainerprofile',
	getAllClientReport: '/api/tcr/trainers/view/AllClientReports',
	// getLearnersReport: "/api/tcr/trainers/view/Learners",
	getLearnersReport: '/api/tcr/trainers/get_learners',
	assignTrainer: '/api/tcr/trainers/assign_trainer',
	deleteTrainer: '/api/tcr/trainers/delete_assigned_trainer',
	assignLearner: '/api/tcr/trainers/assign_learner',
	pairLearner: '/api/tcr/trainers/pair_learner',
	updateLearnerUln: 'api/tcr/trainers/update_uln',

	//TCR History
	historyFilter: '/api/tcr/history/lookupparams',
	getTCRHistory: '/api/tcr/history/get_history',
	viewHistory: '/api/tcr/history/view_history',
	historyExportCsv: '/api/tcr/history/exportcsv',
	fetchFileList: '/api/tcr/reports/filedrop/fetch_file_list',
	fileDownload: '/api/tcr/reports/filedrop/download_file',
	resendInvite: '/api/tcr/trainers/resend_invite',
	assignTrainerToClient: '/api/tcr/trainers/assign_trainer_to_client',
	deleteAssignedTrainer: '/api/tcr/trainers/delete_assigned_trainer_to_client'
}
export default apiUrl
